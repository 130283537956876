import React, { useEffect } from 'react';
import { DomService } from '../services/dom';
// import { StoryDataFromGraphQLQuery } from '../services/language/types';
import { StoryblokService } from '../services/storyblok';
import { getComponent } from '../helpers/get-component';
import { PageContextStory, Story } from '../services/build/types';

const RocheGlobalConfig = getComponent('roche-global-config') as React.ElementType;

const syntheticStory: Story<PageContextStory> = {
  allSpaceLocales: [],
  alternates: [],
  content: {
    _uid: 'preview-login',
    component: '',
    _editable: '',
    body: [],
    disable_global_body_injected_markup: false,
    disable_global_head_injected_markup: false,
    fixed_header: false,
    hide_breadcrumbs: false,
    hide_footer: false,
    hide_language_switch: false,
    hide_navigation: false,
    hide_search_engines: false,
    hide_share: false,
    hide_stock_price: false,
    local_injected_markup: [],
    meta_tags: null,
    microsite_header: false,
    microsite_title: [],
    remove_header: false,
    skip_page_build: false,
    tag_group: [],
    tag: [],
    teaser_description: '',
    teaser_image: null,
    teaser_overline: '',
    teaser_title: '',
    teaser_type: '',
    first_published_at: '',
    pageLanguage: '',
  },
  created_at: new Date(),
  full_slug: '/preview-login-wall',
  group_id: '',
  id: undefined,
  pageLanguage: null,
  name: 'preview-login-wall',
  parent_id: null,
  position: 0,
  first_published_at: null,
  slug: '/preview-login-wall',
  uuid: '',
  lang: 'default',
};

const PreviewLoginWall = () => {
  useEffect(() => {
    window.addEventListener('rocheLoginComplete', () => StoryblokService.redirect());
  }, []);

  return (
    <>
      <RocheGlobalConfig {...DomService.getGlobalConfig(syntheticStory)}></RocheGlobalConfig>

      <roche-header></roche-header>
      <roche-grid>
        <roche-single-sign-in style={{ textAlign: 'center' }}></roche-single-sign-in>
      </roche-grid>
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default PreviewLoginWall;
